
























































































































































































































.container {
    background-color: #f8f8f8;
    // height: calc(100vh + 110px);
    height: 100%;
    // height: calc(100vh + 110px);
    .content {
        background-color: #f8f8f8;
        padding-bottom: 1rem;
    }
}
.user_msg {
    background-color: #ffffff;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
    font-family: PingFang SC;
    .msg {
        padding: 0.4rem 0.2rem 0.16rem 0.2rem;
        .msg-top {
            display: flex;
            padding-bottom: 0.32rem;
            .msg_left {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-left: 1.45rem;
                .img {
                    width: 1.2rem;
                    height: 1.2rem;
                    img {
                        border-radius: 0.5rem;
                        width: 100%;
                        height: 100%;
                    }
                }
                .name {
                    text-align: center;
                    color: #333333;
                    font-size: 0.28rem;
                    font-weight: 500;
                }
            }
            .msg_right {
                margin-left: 0.4rem;
                .time {
                    color: #333333;
                    font-size: 0.24rem;
                    font-weight: 500;
                }
                .VIP {
                    margin-top: 0.1rem;
                    display: flex;
                    align-items: center;
                    .img_vip {
                        // width: 0.24rem;
                        // height: 0.24rem;
                        img {
                            width: 0.252399rem;
                            height: 0.2218rem;
                        }
                    }
                    .vip_number {
                        margin-left: 0.1rem;
                        font-size: 0.24rem;
                        color: #333333;
                    }
                }
                .type {
                    margin-top: 0.1rem;
                    color: #5899f6;
                    background-color: #e8f1fe;
                    padding: 0.06rem 0.2rem 0.04rem 0.2rem;
                    font-size: 0.24rem;
                    text-align: center;
                    font-weight: 500;
					border-radius: 0.08rem;
                }
            }
        }
    }
    .shuju {
        padding: 0.28rem 0.68rem 0.28rem 0.68rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .list1 {
            display: flex;
            align-items: center;
            .top_left {
                padding-left: 0.3rem;
                padding-right: 0.8rem;
                text-align: center;
                border-right: 0.02rem solid #b7d4ff;
            }
            .top_right {
                padding: 0 0.4rem;
                text-align: center;
            }
            .txt {
                color: #666666;
                font-size: 0.24rem;
                transform: scale(0.8);
            }
            .txt1 {
                color: #d98181;
                font-size: 0.24rem;
                transform: scale(0.8);
            }
            .left_num {
                text-align: center;
                color: #5998fc;
                font-size: 0.28rem;
            }
        }
    }
    .list {
        margin: 0 0.68rem;
        padding-bottom: 0.5rem;
        .list_item {
            padding: 0.18rem 0;
            border-bottom: 1px solid #e9e9e9;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 0.28rem;
            color: #333333;
            font-weight: 500;
        }
        .list-right {
            text-align: right;
        }
        .list_item:last-child {
            padding-bottom: 0.5rem;
        }
    }
}
.bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    padding: 0;
    .button {
        margin: 0.14rem 0.3rem 0 0.28rem;
		width: 6.9rem;
		height: 0.88rem;
		line-height: 0.88rem;
        background-color: #5899f6;
        color: #ffffff;
        font-size: 0.32rem;
        text-align: center;
        border-radius: 0.12rem;
        font-weight: 500;
    }
}
.tan1 {
    width: 5.6rem;
    height: 3.6rem;
    background-image: url(../../assets/imgs/tan_bg.png);
    background-repeat: no-repeat;
    background-size: 100% 3.6rem;
    .tan_no_txt {
        padding: 1.5rem 0.82rem 0 0.6rem;
        color: #333333;
        font-size: 0.28rem;
        text-align: center;
        font-weight: 500;
    }
    .tan_no_txt1 {
        padding: 1.3rem 0.82rem 0 0.72rem;
        color: #333333;
        font-size: 0.2rem;
        text-align: left;
        font-weight: 500;
        color: #333333;
    }
    .tan_no_txt2 {
        margin-top: 0.05rem;
        color: #333333;
        font-size: 0.16rem;
        transform: scale(0.75);
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        color: #666666;
        text-align: left;
        .right {
            color: #d98181;
        }
    }
    .input {
        padding: 0 0.7rem 0 0.72rem;
        color: #333333;
        font-weight: bold;
        input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #d8d8d8;
            padding: 0.04rem 0;
            font-size: 0.3rem;
        }
    }
    .tan_no_button {
        margin: 0.3rem 1.2rem 0 1.32rem;
        padding: 0.1rem 0.36rem 0.08rem 0.36rem;
        font-size: 0.28rem;
        color: #ffffff;
        background-color: #5899f6;
        text-align: center;
        border-radius: 0.16rem;
    }
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;